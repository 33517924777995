import Vue from 'vue';
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui中文包
import elemengtLocale from 'element-ui/lib/locale' // 引入elementuiui语言包模块
import enLocale from '@/languages/en.json' // 本地英文包
import zhLocale from '@/languages/zh.json' // 本地中文包

Vue.use(VueI18n);
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
});
const messages = {
    'zh': {
        ...zhLocale,
        ...elementZhLocale
    },
    'en': {
        ...enLocale,
        ...elementEnLocale
    }
}
const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'en',
    messages,
});
Vue.prototype.$t = (key, value) => i18n.t(key, value);

export default i18n;