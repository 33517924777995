<template>
  <div class="avue-contail"
       :class="{'avue--collapse':isCollapse}">
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top />
    </div>

    <div class="avue-layout">
      <div class="avue-left">
        <!-- 左侧导航栏 -->
        <sidebar />
      </div>
      <div class="avue-main">
        <!-- 顶部标签卡 -->
        <tags />
        <!-- 主体视图层 -->
        <el-scrollbar style="height:100%" id="leftHeight" :viewStyle="viewStyle">
          <keep-alive>
            <router-view class="avue-view"
                         v-if="$route.meta.$keepAlive" />
          </keep-alive>
          <router-view class="avue-view"
                       v-if="!$route.meta.$keepAlive" />
        </el-scrollbar>

      </div>
    </div>
    <!-- <el-footer class="avue-footer">
      <img src="/svg/logo.svg"
           alt=""
           class="logo">
      <p class="copyright">© 2018 Avue designed by smallwei</p>
    </el-footer> -->
    <div class="avue-shade"
         @click="showCollapse"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tags from './tags'
import top from './top/'
import sidebar from './sidebar/'
import admin from '@/util/admin';
export default {
  components: {
    top,
    tags,
    sidebar
  },
  name: 'index',
  provide () {
    return {
      index: this
    };
  },
  data () {
    return {
      viewStyle:"",
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: '',
    }
  },
  watch: {
      $route: function () {
          if (this.$route.path == "/device/devFile" || this.$route.path == "/listArray/pepoleChange" || this.$route.path == "/wkln/wklnChange" || this.$route.path == "/productionManagement/additionToMachine") {
              this.viewStyle = {
                  "overflow": "hidden",
              }
          } else {
              this.viewStyle = {
                  "overflow": "auto",
              }
          }
      }
  },
  created() {
      if (this.$route.path == "/device/devFile" || this.$route.path == "/listArray/pepoleChange" || this.$route.path == "/wkln/wklnChange" || this.$route.path == "/productionManagement/additionToMachine") {
          this.viewStyle = {
              "overflow": "hidden",
          }
      } else {
          this.viewStyle = {
              "overflow": "auto",
          }
      }
  },
  mounted () {
    this.init();
  },
  computed: mapGetters(['isLock', 'isCollapse', 'website']),
  props: [],
  methods: {
    //打开菜单
    openMenu (item = {}) {

      this.$store.dispatch("GetMenu", item.parentId).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
        }
        //当点击顶部菜单做的事件
        if (!this.validatenull(item)) {
          let itemActive,
            childItemActive = 0;
          if (item.href) {
            itemActive = item;
          } else {
            if (this.menu[childItemActive].length == 0) {
              itemActive = this.menu[childItemActive];
            } else {
              itemActive = this.menu[childItemActive].children[childItemActive];
            }
          }
          this.$store.commit('SET_MENUID', item);
          this.$router.push({
            path: this.$router.$avueRouter.getPath({
              name: itemActive.label,
              src: itemActive.href
            })
          });
        }

      });
    },
    showCollapse () {
      this.$store.commit("SET_COLLAPSE");
    },
    // 屏幕检测
    init () {
      this.$store.commit('SET_SCREEN', admin.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', admin.getScreen());
        }, 0);
      }
    }
  }
}
</script>
