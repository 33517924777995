var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avue-top" }, [
    _c("div", { staticClass: "top-bar__left" }, [
      _vm.showCollapse
        ? _c("div", { staticClass: "avue-breadcrumb" }, [
            _c("i", {
              staticClass: "iconfont icon-caidanzuo",
              class: [{ "icon-caidanyou": _vm.isCollapse }],
              staticStyle: {
                "font-size": "25px !important",
                "line-height": "50px"
              },
              on: { click: _vm.setCollapse }
            })
          ])
        : _vm._e()
    ]),
    _c("h1", { staticClass: "top-bar__title" }),
    _c(
      "div",
      {
        staticClass: "top-bar__right",
        staticStyle: { height: "25px!important" }
      },
      [
        _c(
          "el-tooltip",
          {
            attrs: {
              effect: "dark",
              content: _vm.$t("Company"),
              placement: "bottom"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "top-bar__item",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c("img", {
                  staticStyle: {
                    color: "darkslategrey",
                    "font-size": "1.125rem !important",
                    width: "1.25rem",
                    "text-align": "center",
                    "vertical-align": "middle"
                  },
                  attrs: {
                    title: _vm.$t("Company"),
                    src: "/img/Bnt/company.png"
                  }
                })
              ]
            )
          ]
        ),
        _c(
          "el-dropdown",
          {
            staticStyle: { width: "auto", "padding-right": "12px" },
            attrs: { trigger: "click" }
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "padding-top": "4px",
                  "font-weight": "400",
                  "font-size": "16px",
                  color: "dimgrey"
                }
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.shortName ? _vm.shortName : "") +
                    "\n              "
                ),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.gridData, function(item) {
                return _c(
                  "el-dropdown-item",
                  {
                    key: item.id,
                    nativeOn: {
                      click: function($event) {
                        return _vm.changeCompany(item.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                  " +
                        _vm._s(item.name) +
                        "\n              "
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "el-tooltip",
          {
            attrs: {
              effect: "dark",
              content: _vm.$t("UserName"),
              placement: "bottom"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "top-bar__item",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c("img", {
                  staticStyle: {
                    color: "darkslategrey",
                    "font-size": "1.125rem !important",
                    width: "1.25rem",
                    "text-align": "center",
                    "vertical-align": "middle"
                  },
                  attrs: { title: _vm.$t("UserName"), src: "/img/Bnt/user.png" }
                })
              ]
            )
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "padding-top": "4px",
              "font-weight": "400",
              "font-size": "16px",
              "padding-right": "15px",
              color: "dimgrey"
            }
          },
          [_vm._v("\n          " + _vm._s(_vm.oidcUser.name) + "\n      ")]
        ),
        _c(
          "el-tooltip",
          {
            attrs: {
              effect: "dark",
              content: _vm.$t("UserName"),
              placement: "bottom"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "top-bar__item",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c("img", {
                  staticStyle: {
                    color: "darkslategrey",
                    "font-size": "1.125rem !important",
                    width: "1.25rem",
                    "text-align": "center",
                    "vertical-align": "middle"
                  },
                  attrs: { title: _vm.$t("Language"), src: "/img/Bnt/en.png" }
                })
              ]
            )
          ]
        ),
        _c(
          "el-dropdown",
          { staticStyle: { width: "auto" }, attrs: { trigger: "click" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  "padding-top": "4px",
                  "font-weight": "400",
                  "font-size": "16px",
                  color: "dimgrey"
                }
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.languageVal ? _vm.languageVal : "") +
                    "\n              "
                ),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
              ]
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.language, function(item) {
                return _c(
                  "el-dropdown-item",
                  {
                    key: item.value,
                    nativeOn: {
                      click: function($event) {
                        return _vm.getValue(item)
                      }
                    }
                  },
                  [
                    _c("span", { staticStyle: { "padding-left": "6px" } }, [
                      _vm._v(_vm._s(item.label))
                    ])
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _vm.showDebug
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.logsFlag
                    ? "没有错误日志"
                    : _vm.logsLen + "条错误日志",
                  placement: "bottom"
                }
              },
              [_c("div", { staticClass: "top-bar__item" })]
            )
          : _vm._e(),
        _vm.showTheme
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.$t("Characteristic Theme"),
                  placement: "bottom"
                }
              },
              [_c("div", { staticClass: "top-bar__item top-bar__item--show" })]
            )
          : _vm._e(),
        _vm.showFullScren
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.isFullScren
                    ? _vm.$t("Exit Full Screen")
                    : _vm.$t("Full Screen"),
                  placement: "bottom"
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          this.$route.name == "首页" ||
                          this.$route.name == "实时看板" ||
                          this.$route.name == "Real time production Kanban" ||
                          this.$route.name == "Typesetting Management" ||
                          this.$route.name == "Personnel ReplacementS" ||
                          this.$route.name == "Equipment Details" ||
                          this.$route.name == "Process Modification" ||
                          this.$route.name == "Operational Manage" ||
                          this.$route.name == "Home Page" ||
                          this.$route.name == "PVH Home Page" ||
                          this.$route.name == "TracingReport",
                        expression:
                          "this.$route.name =='首页'|| this.$route.name =='实时看板'|| this.$route.name =='Real time production Kanban' || this.$route.name =='Typesetting Management'||this.$route.name =='Personnel ReplacementS'||this.$route.name =='Equipment Details'||this.$route.name =='Process Modification'||this.$route.name =='Operational Manage'||this.$route.name =='Home Page'||this.$route.name =='PVH Home Page'||this.$route.name =='TracingReport'"
                      }
                    ],
                    staticClass: "top-bar__item",
                    staticStyle: { cursor: "pointer" }
                  },
                  [
                    _c("i", {
                      class: _vm.isFullScren
                        ? "iconfont icon-tuichuquanping"
                        : "iconfont icon-caozuo_quanpingfangda",
                      on: { click: _vm.handleScreen }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        _c(
          "el-tooltip",
          {
            attrs: {
              effect: "dark",
              content: _vm.$t("Exit System"),
              placement: "bottom"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "top-bar__item",
                staticStyle: { cursor: "pointer" }
              },
              [
                _c("i", {
                  staticClass: "el-icon-switch-button",
                  staticStyle: {
                    color: "darkslategrey",
                    "font-size": "1.125rem !important",
                    width: "1.25rem",
                    "text-align": "center",
                    "vertical-align": "middle"
                  },
                  on: { click: _vm.toLogout }
                })
              ]
            )
          ]
        ),
        _c("top-setting")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }