import Vue from 'vue';
import VueI18n from 'vue-i18n'
import i18n from '@/languages/lang.js'
import ElementUI from 'element-ui';
import anime from 'animejs';
import axios from './router/axios';
import VueAxios from 'vue-axios';
import App from './App';
import router from './router/router';
import './permission'; // 权限
import './error'; // 日志
import store from './store';
import * as echarts from 'echarts';
import { loadStyle } from './util/util'
import './util/rem'
import * as urls from '@/config/env';
import 'element-ui/lib/theme-chalk/index.css';
//iconfont
import '@/styles/font/iconfont.css'
import {
  iconfontUrl,
  iconfontVersion
} from '@/config/env';
import * as filters from './filters' // 全局filter
import './styles/common.scss';
import './styles/pvh.scss'
// 引入avue的包
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css'

import basicContainer from './components/basic-container/main'
import pvhLayout from './page/pvh/pvh'
import VueClipboard from 'vue-clipboard2'
import formCreate  from '@form-create/element-ui'
import print from '@/util/print'
Vue.use(print)
// 插件 json 展示
import vueJsonTreeView from 'vue-json-tree-view'
import proApis from './api/productionManagement/additionToMachine'
import request from '@/router/axios';
// import request from '@/util/request';
//多语言
//import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui英文包
//import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui中文包
//import elemengtLocale from 'element-ui/lib/locale' // 引入elementuiui语言包模块
//import enLocale from '@/languages/en.json' // 本地英文包
//import zhLocale from '@/languages/ch.json' // 本地中文包
import  VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// markdown
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);

Vue.prototype.request = request
Vue.prototype.proApis = proApis
Vue.prototype.$echarts = echarts
Vue.prototype.$anime = anime
Vue.use(Avue)
//Vue.use(anime)
Vue.use(VueI18n);

Vue.use(router)
Vue.use(VueClipboard)

Vue.use(ElementUI,{
    i18n: (key, value) => i18n.t(key, value)
});

Vue.use(vueJsonTreeView)

Vue.use(VueAxios, axios)
Vue.use(formCreate)
Vue.use(VueQuillEditor)
//多语言
//const messages = {
//            'ch': {
//                ...zhLocale,
//                ...elementZhLocale
//            },
//            'en': {
//                ...enLocale,
//                ...elementEnLocale
//            }
//        }
//const i18n = new VueI18n({
//    locale: localStorage.getItem('locale') || 'en',
//    messages, 
//});
Vue.prototype.$t = (key, value) => i18n.t(key, value);
//注册全局容器
Vue.component('basicContainer', basicContainer)
Vue.component('pvhLayout', pvhLayout)
// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key];
})

// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
  loadStyle(iconfontUrl.replace('$key', ele));
})

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')