import Layout from '@/page/index/'

export default [
    {
        path: '/tracingSearch',
        component: Layout,
        redirect: '/wel/tracingSearch',
        children: [{
            path: 'pvhIndex',
            name: 'PVH Home Page',
            component: resolve => (require(['@/page/tracingSearch'], resolve)),
        }]
    },
    /*{
        path: '/visualData',
        component: Layout,
        redirect: '/wel/visualData',
        children: [{
            path: 'index',
            name: 'MES Home Page',
            component: resolve => (require(['@/page/visualData'], resolve)),
        }]
    },*/
    {
        path: '/wel',
        component: Layout,
        redirect: '/wel/index',
        children: [{
            path: 'index',
            name: 'Home Page',
            component: resolve => (require(['@/page/wel'], resolve)),
        }]
    },
    //{
    //    path: '/express',
    //    component: Layout,
    //    redirect: '/wel/express',
    //    children: [{
    //        path: 'index',
    //        name: 'Express',
    //        component: () =>
    //            import('@/page/express'], resolve)),
    //    }]
    //},
    {
        path: '/visualData',
        component: Layout,
        redirect: '/wel/visualData',
        children: [{
            path: 'index',
            name: 'Real time production Kanban',
            component: resolve => (require(['@/page/visualData'], resolve)),
        }]
    },
    //{
    //    path: '/tracingReport',
    //    component: Layout,
    //    redirect: '/wel/tracingReport',
    //    children: [{
    //        path: 'index',
    //        name: 'TracingReport',
    //        component: () =>
    //            import('@/page/tracingReport'], resolve)),
    //    }]
    //},
    {
        path: '/info',
        component: Layout,
        redirect: '/info/index',
        children: [{
            path: 'index',
            name: 'personal Information',
            component: resolve => (require(['@/views/info/index'], resolve)),
        }, {
            path: 'message',
            name: 'Message Center',
            component: resolve => (require(['@/views/info/message'], resolve)),
        }]
    }, {
        path: '/advanced-router',
        component: Layout,
        children: [{
            path: 'mutative-detail/:id',
            name: 'Dynamic Routing Details Page',
            component: resolve => (require(['@/views/advanced-router/mutative-detail'], resolve)),
        }, {
            path: 'argument-detail',
            name: 'Parameter Routing Details Page',
            component: resolve => (require(['@/views/advanced-router/argument-detail'], resolve)),
        }]
    }, {
        path: '/basic',
        component: Layout,
        children: [{
            path: 'dic',
            name: 'Dictionary Manage',
            component: resolve => (require(['@/views/basis/dic'], resolve)),
        },
            {
                path: "tip",
                name: "Post Manage",
                component: resolve => (require(["@/views/basis/tip"], resolve)),
            },
            {
                path: "fault",
                name: "Fault Information",
                component: resolve => (require(["@/views/basis/fault"], resolve)),
            },
            {
                path: "sim",
                name: "Environmental Regionalization",
                component: resolve => (require(["@/views/basis/sim"], resolve)),
            }, {
                path: "wkln",
                name: "Process Manage",
                component: resolve => (require(["@/views/basis/wkln"], resolve)),
            }, {
                path: "math",
                name: "Unit Of Measurement",
                component: resolve => (require(["@/views/basis/math"], resolve)),
            },
            {
                path: "math",
                name: "Unit Of Measurement",
                component: resolve => (require(["@/views/basis/math"], resolve)),
            },

            {
                path: 'scheduleProcess',
                name: 'Schedule Process Manage',
                component: resolve => (require(['@/views/basis/scheduleProcess'], resolve)),
            }, {
                path: 'workp',
                name: 'Workshop Manage',
                component: resolve => (require(['@/views/basis/workp'], resolve)),
            }, {
                path: "color",
                name: "Coil Color",
                component: resolve => (require(["@/views/basis/color"], resolve)),
            }, {
                path: "warn",
                name: "Energy Consumption Warning Settings",
                component: resolve => (require(["@/views/basis/warn"], resolve)),
            }, {
                name: "DeclarationField",
                path: "declareFieldList",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/basis/declareFieldList'], resolve)),
            }, {
                name: "DeclarationFile",
                path: "declareFileList",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/basis/declareFileList'], resolve)),
            }, {
                name: "Enumeration Management",
                path: "enumeration",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/basis/enumeration'], resolve)),
            }, {
                path: "shifts",
                name: "Manage the class",
                component: resolve => (require(["@/views/basis/shifts"], resolve)),
            }, {
                path: "mailSettings",
                name: "mail settings",
                component: resolve => (require(["@/views/basis/mailSettings"], resolve)),
            }, {
                path: "supplierContactSettings",
                name: "Supplier Contact Settings",
                component: resolve => (require(["@/views/basis/supplierContactSettings"], resolve)),
            }, {
                path: "addMailSettings",
                name: "add Mail Settings",
                component: resolve => (require(["@/views/basis/addMailSettings"], resolve)),
            }]
    }, {
        path: '/system',
        component: Layout,
        children: [{
            name: "FieldSettingList",
            path: "fieldList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/system/fieldList'], resolve)),
        }, {
            name: "FileSettingList",
            path: "fileList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/system/fileList'], resolve)),
        }, {
            path: "customRules",
            name: "Custom Rules",
            component: resolve => (require(["@/views/system/customRules"], resolve)),
        }, {
            path: "basicSettings",
            name: "Basic Settings",
            component: resolve => (require(["@/views/system/basicSettings"], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'pepoleOrder',
            name: 'Personnel Manage',
            component: resolve => (require(['@/views/peop/pepoleOrder'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'userList',
            name: 'User Management',
            component: resolve => (require(['@/views/peop/userList'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'userPermission',
            name: 'User Permission',
            component: resolve => (require(['@/views/peop/userPermission'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'roleList',
            name: 'Role Management',
            component: resolve => (require(['@/views/peop/roleList'], resolve)),
        }]
    },
    {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'rolePermission',
            name: 'Role Permission',
            component: resolve => (require(['@/views/peop/rolePermission'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'roleToUser',
            name: 'Role To User',
            component: resolve => (require(['@/views/peop/roleToUser'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'userToRole',
            name: 'User To Role',
            component: resolve => (require(['@/views/peop/userToRole'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'classp',
            name: 'Department Manage',
            component: resolve => (require(['@/views/peop/classp'], resolve)),
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'tip',
            name: 'Post Manage',
            component: resolve => (require(['@/views/basis/tip'], resolve)),
        }]
    }, {
        path: '/mtr',
        component: Layout,
        children: [{
            path: 'classificationGroup',
            name: 'Product Classification Group',
            component: resolve => (require(['@/views/mtr/classificationGroup'], resolve)),
        },{
            path: 'mtrlist',
            name: 'Material Classification',
            component: resolve => (require(['@/views/mtr/mtrlist'], resolve)),
        }, {
            path: 'mtrD',
            name: 'Material File',
            component: resolve => (require(['@/views/mtr/mtrD'], resolve)),
        }, {
            path: "mtrDic",
            name: "Material Properties",
            component: resolve => (require(["@/views/mtr/mtrDic"], resolve)),
        }, {
            path: "workNo",
            name: "Batch Manage",
            component: resolve => (require(["@/views/mtr/workNo"], resolve)),
        }, {
            path: "materialsaddedit",
            name: "materialsaddedit",
            component: resolve => (require(["@/views/mtr/materialsaddedit"], resolve)),
        }]
    },
    {
        path: '/yieldManagement',
        component: Layout,
        children: [{
            path: 'refineStyle',
            name: 'Variety Segmentation',
            component: resolve => (require(['@/views/yieldManagement/refineStyle'], resolve)),
        }, {
            path: 'monthProduct',
            name: 'Monthly Yield Of Product',
            component: resolve => (require(['@/views/yieldManagement/monthlyYieldOfProduct'], resolve)),
        }, {
            path: 'monthShift',
            name: 'Monthly Yield Of Shift',
            component: resolve => (require(['@/views/yieldManagement/monthlyYieldOfShift'], resolve)),
        }, {
            path: 'monthUser',
            name: 'Monthly Yield Of User',
            component: resolve => (require(['@/views/yieldManagement/monthlyYieldOfUser'], resolve)),
        }]
    },

    {
        path: '/searchDashboard',
        component: Layout,
        children: [{
            path: 'visualData',
            name: '实时看板',
            component: resolve => (require(['@/page/visualData'], resolve)),
        }, {
            path: 'formStyle',
            name: 'formStyle',
            component: resolve => (require(['@/views/searchDashboard/formStyle'], resolve)),
        }, {
            path: 'usersStyle',
            name: 'Users Report',
            component: resolve => (require(['@/views/searchDashboard/usersStyle'], resolve)),
        }, {
            path: "reportStyle",
            name: "Multifunctional Report",
            component: resolve => (require(["@/views/searchDashboard/reportStyle"], resolve)),
            }
            , {
                name: "DeclareFile",
                path: "declareFile",
                component: resolve => (require(["@/views/searchDashboard/declareFile"], resolve)),
            }
            , {
                name: "PurchaseDetails",
                path: "purchaseDetails",
                component: resolve => (require(["@/views/searchDashboard/purchaseDetails"], resolve)),
            }
            , {
                name: "TracingReport",
                path: "tracingReport",
                component: resolve => (require(["@/views/searchDashboard/tracingReport"], resolve)),
            }
            , {
                name: "SupplierOrder",
                path: "supplierOrder",
                component: resolve => (require(["@/views/searchDashboard/supplierOrder"], resolve)),
            }, {
            name: "UserAccess",
                path: "userAccess",
                component: resolve => (require(["@/views/searchDashboard/userAccess"], resolve)),
        }]
    },

    {
        path: '/listArray',
        component: Layout,
        children: [{
            path: 'listClass',
            name: 'Typesetting Management',
            component: resolve => (require(['@/views/listArray/listClass'], resolve)),
        }, {
            path: "machineSetting",
            name: "Personnel Machine SettingS",
            component: resolve => (require(['@/views/listArray/machineSetting'], resolve)),
        }, {
            path: "pepoleChange",
            name: "Personnel ReplacementS",
            component: resolve => (require(['@/views/listArray/pepoleChange'], resolve)),
        }]
    }, {
        path: "/device",
        name: "Device Manage",
        component: Layout,
        children: [
            {
                name: "Equipment Model",
                path: "deviceType",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/device/deviceType'], resolve)),
            }, {
                name: "Equipment Files",
                path: "index",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/device/index'], resolve)),
            }, {
                name: "Equipment Details",
                path: "devFile",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/device/devFile'], resolve)),
            }, {
                path: "maintain",
                name: "Maintenance",
                component: resolve => (require(["@/views/basis/maintain"], resolve)),
            }, {
                path: "warnList",
                name: "Fault Manage",
                component: resolve => (require(["@/views/basis/warnList"], resolve)),
            }, {
                name: "Object Model Manage",
                path: "thingsModel",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/device/thingsModel'], resolve)),
            },
            {
                name: "Function Definition",
                path: "indexFunction",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/device/indexFunction'], resolve)),
            }
        ]
    }, {
        path: "/contentCoupletSet",
        name: "Internet Of Things Settings",
        component: Layout,
        children: []
    },
    // 新添加的测试
    {
        path: "/pess",
        name: "Setting",
        component: Layout,
        children: [{
            name: "Test One",
            path: "oneindex",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pess/oneindex'], resolve)),
        }, {
            name: "Test Two",
            path: "twoindex",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pess/twoindex'], resolve)),
        }]
    },
    {
        path: "/wkln",
        name: "Processs Manage",
        component: Layout,
        children: [{
            name: "Standard Process Sheet",
            path: "standardList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/standardList'], resolve)),
        }, {
            name: "Process Project",
            path: "wklnObject",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/wklnObject'], resolve)),
        }, {
            name: "Add Standard Process Sheet",
            path: "addstandard",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/addstandard'], resolve)),
        }, {
            name: "Add Actual Process Order",
            path: "addreality",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/addreality'], resolve)),
        }, {
            name: "Edit Standard Process Sheet",
            path: "editstandrd",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/editStandard'], resolve)),
        }, {
            name: "Edit actual Process Sheet",
            path: "editreality",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/editReality'], resolve)),
        }, {
            name: "Standard Process Sheet Details",
            path: "lookstandrd",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/lookStandard'], resolve)),
        }, {
            name: "Actual Process Sheet Details",
            path: "lookreality",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/lookReality'], resolve)),
        }]
    }, {
        path: "/productionManagement",
        name: "Production Manage",
        redirect: "/additionToMachine",
        component: Layout,
        children: [{
            name: "Operational Manage",
            path: "additionToMachine",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/productionManagement/additionToMachine'], resolve)),
        }]
    }, {
        path: "/successionManagement",
        name: "Succession Management",
        component: Layout,
        children: [{
            name: "Reset Management",
            path: "resetLengthManagement",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/succession/resetLengthManagement'], resolve)),
        }, {
            name: "Change Shift Management",
            path: "changeShiftManagement",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/succession/changeShiftManagement'], resolve)),
        }]
    }, {
        path: "/plan",
        name: "Plan Manage",
        component: Layout,
        children: [{
            name: "Planned process sheet",
            path: "planList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/planList'], resolve)),
        }, {
            name: "Actual Process Sheet",
            path: "realityList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/realityList'], resolve)),
        }, {
            name: "Process Modification",
            path: "wklnChange",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/wklnChange'], resolve)),
        }, {
            name: "Add planned process sheet",
            path: "addPlanList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/addPlanList'], resolve)),
        }, {
            name: "Edit planned process sheet",
            path: "editPlanList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/wkln/editPlanList'], resolve)),
        }]
    }, {
        path: "/productionManagement",
        name: "Production Manage",
        redirect: "/additionToMachine",
        component: Layout,
        children: [{
            name: "Operational Manage",
            path: "additionToMachine",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/productionManagement/additionToMachine'], resolve)),
        }]
    },
    {
        path: "/pvh_factory",
        name: "Spinning Mill",
        redirect: "/materialOrd",
        component: Layout,
        children: [{
            name: "RawMaterialPurchase",
            path: "materialOrd",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_factory/materialOrd'], resolve)),
        }, {
            name: "Information Declaration",
            path: "declarationEntry",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_factory/declarationEntry'], resolve)),
        }, {
            name: "Purchase-Order List",
            path: "purchaseOrd",
            icon: 'el-icon-document',
            meta: {
                keepAlive: true, //此组件需要被缓存
            },
            component: resolve => (require(['@/views/pvh_factory/purchaseOrd'], resolve)),
        }, {
            name: "Sale-Order List",
            path: "salesOrd",
            icon: 'el-icon-document',
            meta: {
                keepAlive: true, //此组件需要被缓存
            },
            component: resolve => (require(['@/views/pvh_factory/salesOrd'], resolve)),
        }, {
            name: "Master Purchase-Order List",
            path: "agentpurchaseOrd",
            icon: 'el-icon-document',
            meta: {
                keepAlive: true, //此组件需要被缓存
            },
            component: resolve => (require(['@/views/pvh_factory/agentpurchaseOrd'], resolve)),
        }, {
            name: "Purchase-Order Info",
            path: "purchaseAdd",
            icon: 'el-icon-document',
            meta: {
                keepAlive: true, //此组件需要被缓存
            },
            component: resolve => (require(['@/views/pvh_factory/purchaseAdd'], resolve)),
        },
            {
                name: "PO AGENT INFO",
                path: "purchaseAdd_AgenEntryPo",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_factory/purchaseAdd_AgenEntryPo'], resolve)),
            }, {
                name: "ADD Contract",
                path: "contractAdd",
                icon: 'el-icon-document',
                meta: {
                    keepAlive: true, //此组件需要被缓存
                },
                component: resolve => (require(['@/views/pvh_factory/contractAdd'], resolve)),
            }, {
                name: "ContractManagement",
                path: "contractList",
                icon: 'el-icon-document',
                meta: {
                    keepAlive: true, //此组件需要被缓存
                },
                component: resolve => (require(['@/views/pvh_factory/contractList'], resolve)),
            }
        ]
    },
    {
        path: "/pvh_operation",
        name: "Operation Interface",
        redirect: "/orderFracing",
        component: Layout,
        children: [{
            name: "OrderTraceability",
            path: "orderFracing",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_operation/orderFracing'], resolve)),
        }, {
            name: "pdfLog",
            path: "pdfLog",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_operation/pdfLog'], resolve)),
        }, {
            name: "review",
            path: "review",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_operation/review'], resolve)),
        },
            {
                name: "loadSpeed",
                path: "loadSpeed",
                icon: 'el-icon-document',
                meta: {
                    isTab: false, //此组件需要被缓存
                },
                component: resolve => (require(['@/views/pvh_operation/loadSpeed'], resolve)),
            },
            {
                name: "PdfGenerationList",
                path: "PdfGenerationList",
                icon: 'el-icon-document',
                meta: {
                    isTab: false, //此组件需要被缓存
                },
                component: resolve => (require(['@/views/pvh_operation/PdfGenerationList'], resolve)),
            },
            {
                name: "PRODUCT TREE TRACING",
                path: "orderTree",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_operation/orderTree'], resolve)),
            },
        ]
    },
    {
        path: "/pvh_search",
        name: "Product tracing",
        redirect: "/tracingSearch",
        component: Layout,
        children: [{
            name: "Product tracing",
            path: "tracingSearch",
            icon: 'el-icon-s-help',
            component: resolve => (require(['@/views/pvh_search/tracingSearch'], resolve)),
        },
            {
                name: "ListModel",
                path: "ListModel",
                icon: 'el-icon-s-help',
                component: resolve => (require(['@/views/pvh_search/ListModel'], resolve)),
            }, {
                name: "SubjectList",
                path: "subjectList",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_search/subjectList'], resolve)),
            }, {
                path: "picKingListBaleIDs",
                name: "PickingListBaleIDs",
                component: resolve => (require(["@/views/pvh_search/picKingListBaleIDs"], resolve)),
            }, {
                path: "cottonBaleDetails",
                name: "CottonBaleDetails",
                component: resolve => (require(["@/views/pvh_search/cottonBaleDetails"], resolve)),
            }
        ]
    },
    {
        path: "/pvh_admin",
        name: "Admin Management",
        redirect: "/qrCodeRecode",
        component: Layout,
        children: [{
            name: "QR Code Access Record",
            path: "qrCodeRecode",
            icon: 'el-icon-s-help',
            component: resolve => (require(['@/views/pvh_admin/qrCodeRecode'], resolve)),
        },
            {
                name: "QrCodeJump",
                path: "qrJump",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_admin/qrJump'], resolve)),
            },
            {
                name: "My Operation Log",
                path: "logList",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_admin/logList'], resolve)),
            },
            {
                name: "ApiLogList",
                path: "apiLogList",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_admin/apiLogList'], resolve)),
            },
            {
                name: "AuditLogList",
                path: "auditLogList",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_admin/auditLogList'], resolve)),
            },
        ]
    },
    {
        path: "/pvh_setting",
        name: "Basic Settings",
        redirect: "/dashboard",
        component: Layout,
        children: [{
            name: "DashboardS",
            path: "dashboard",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_setting/dashboard'], resolve)),
        }, {
            name: "SupplierSectionList",
            path: "supplierList",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_setting/supplierList'], resolve)),
        }, {
            name: "SupplierSection",
            path: "supplierAdd",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_setting/supplierAdd'], resolve)),
        }, {
            name: "VendorCertifiedFactory",
            path: "supplierCer",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_setting/supplierCer'], resolve)),
        }, {
            name: "Affiliated Corporation",
            path: "affiliatedCorp",
            icon: 'el-icon-document',
            component: resolve => (require(['@/views/pvh_setting/affiliatedCorp'], resolve)),
        },
            {
                name: "AddressSetting",
                path: "vendorAddress",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_setting/vendorAddress'], resolve)),
            },
            {
                name: "VendorSupplier",
                path: "vendorSupplier",
                icon: 'el-icon-document',
                component: resolve => (require(['@/views/pvh_setting/vendorSupplier'], resolve)),
            },
            // {
            //     name: "test",
            //     path: "test",
            //     icon: 'el-icon-document',
            //     component: resolve => (require(['@/views/pvh_setting/test'], resolve)),
            // },

        ]
    },
    {
        path: "/userCenter",
        name: "User Center",
        redirect: "tokenManagement",
        component: Layout,
        children: [
            {
                path: "tokenManagement",
                name: "Token Management",
                component: resolve => (require(["@/views/userCenter/tokenManagement"], resolve)),
            },
        ]
    },
    {
        path: '/supplier',
        component: Layout,
        children: [{
            name: 'SupplierInfo',
            path: 'supplierInfo',
            component: resolve => (require(['@/views/supplier/supplierInfo'], resolve)),
        },
            {
                name: "SettlementInfo",
                path: "settlementInfo",
                component: resolve => (require(["@/views/supplier/settlementInfo"], resolve)),
        }]
    }, {
        path: '/warehouse',
        component: Layout,
        children: [{
            path: "warehouseType",
            name: "Warehouse Type",
            component: resolve => (require(["@/views/warehouse/warehouseType"], resolve)),
        }, {
            path: "warehouseInfo",
            name: "Warehouse",
            component: resolve => (require(["@/views/warehouse/warehouseInfo"], resolve)),
        }, {
            path: "warehousePosition",
            name: "Warehouse Position",
            component: resolve => (require(["@/views/warehouse/warehousePosition"], resolve)),
        }]
    },
    {
        path: '/custorm',
        component: Layout,
        children: [{
            name: 'CustormInfo',
            path: 'custormInfo',
            component: resolve => (require(['@/views/custorm/custormInfo'], resolve)),
        },
        {
            name: "CustormRelation",
            path: "custormRelation",
            component: resolve => (require(["@/views/custorm/custormRelation"], resolve)),
        }]
    }, {
        path: '/stock',
        component: Layout,
        children: [{
            name: 'StockInfo',
            path: 'stockInfo',
            component: resolve => (require(['@/views/stock/stockInfo'], resolve)),
        },
        {
            name: "StockInout",
            path: "stockInout",
            component: resolve => (require(["@/views/stock/stockInout"], resolve)),
            }, {
            name: "StockInoutDetail",
            path: "stockInoutDetail",
            component: resolve => (require(["@/views/stock/stockInoutDetail"], resolve)),
            },
            {
                name: "StockInoutAdd",
                path: "stockInoutAdd",
                component: resolve => (require(["@/views/stock/stockInoutAdd"], resolve)),
            }]
    },
    {
        path: "/quality",
        name: "quality",
        component: Layout,
        children: [
            {
                path: "parameter",
                name: "parameter",
                component: resolve => (require(["@/views/quality/parameter"], resolve)),//质量参数
            },
            {
                path: "parameterStandard",
                name: "parameterStandard",
                component: resolve => (require(["@/views/quality/parameterStandard"], resolve)),//质量标准
            },
            {
                path: "parameterStandardAdd",
                name: "parameterStandardAdd", //质量标准新增
                component: resolve => (require(["@/views/quality/parameterStandardAdd"], resolve)),//质量标准新增页面
            },
            {
                path: "parameterStandardEdit",
                name: "parameterStandardEdit", //质量标准列表
                component: resolve => (require(["@/views/quality/parameterStandardEdit"], resolve)),//质量标准回显
            },
            {
                path: "materialComparison",
                name: "materialComparison", //物料检测
                component: resolve => (require(["@/views/quality/materialComparison"], resolve)),//物料标准
            },
            {
                path: "materialComparisonAdd",
                name: "materialComparisonAdd",//物料检测新增
                component: resolve => (require(["@/views/quality/materialComparisonAdd"], resolve)),//物料标准新增
            },
            {
                path: "materialComparisonEdit",
                name: "materialComparisonEdit",//物料检测列表
                component: resolve => (require(["@/views/quality/materialComparisonEdit"], resolve)),//物料标准回显
            },
            {
                path: "feedback",
                name: "feedback", //质量反馈参数
                component: resolve => (require(["@/views/quality/feedback"], resolve)),//反馈参数
            },
            {
                path: "feedbackStandard",
                name: "feedbackStandard", //质量反馈标准
                component: resolve => (require(["@/views/quality/feedbackStandard"], resolve)),//反馈标准
            },
            {
                path: "FeedbackStandardAdd",
                name: "FeedbackStandardAdd",//质量反馈标准新增
                component: resolve => (require(["@/views/quality/feedbackStandardAdd"], resolve)),//反馈标准新增
            },
            {
                path: "FeedbackStandardEdit",
                name: "FeedbackStandardEdit",//质量反馈标准查看
                component: resolve => (require(["@/views/quality/feedbackStandardEdit"], resolve)),//反馈标准详情
            },
            {
                path: "canonicalParameter",
                name: "canonicalParameter",
                component: resolve => (require(["@/views/quality/canonicalParameter"], resolve)),//参数标准中间表  暂时舍弃
            },
            {
                path: "materialFeedback",
                name: "materialFeedback", //质量反馈
                component: resolve => (require(["@/views/quality/materialFeedback"], resolve)),//物料反馈
            },
            {
                path: "materialFeedbackAdd",
                name: "materialFeedbackAdd",//质量反馈新增
                component: resolve => (require(["@/views/quality/materialFeedbackAdd"], resolve)),//物料反馈
            },
            {
                path: "materialFeedbackEdit",
                name: "materialFeedbackEdit",//质量反馈查看
                component: resolve => (require(["@/views/quality/materialFeedbackEdit"], resolve)),//物料反馈
            },
        ]
    },
    {
        path: '/datalogs',
        component: Layout,
        children: [{
            path: "datalog",
            name: "Data Log",
            component: resolve => (require(["@/views/datalogs/datalog"], resolve)),
        }, {
            path: "dataImport",
            name: "Data Import",
            component: resolve => (require(["@/views/datalogs/dataImport"], resolve)),
        }, {
            path: "dataInterface",
            name: "Data Interface",
            component: resolve => (require(["@/views/datalogs/dataInterface"], resolve)),
        }]
    },
    {
        path: "/textile_Purchase",
        name: "Purchase",
        component: Layout,
        children: [
            {
                path: "POrd",
                name: "Purchase_Order_List",
                component: resolve => (require(["@/views/textile_Purchase/POrd"], resolve)),
            },
            { 
                path: "PO_Contract",
                name: "Purchase_Contract_List",
                component: resolve => (require(["@/views/textile_Purchase/PO_Contract"], resolve)),
            },
            {
                path: "POrdAdd",
                name: "Purchase_Order",
                component: resolve => (require(["@/views/textile_Purchase/POrdAdd"], resolve)),
            },
            {
                path: "PO_ContractAdd",
                name: "Purchase_Contract",
                component: resolve => (require(["@/views/textile_Purchase/PO_ContractAdd"], resolve)),
            }
        ]
    },
    {
        path: "/textile_Sales",
        name: "Sales",
        component: Layout,
        children: [
            {
                path: "SOrd",
                name: "Sales_Order_List",
                component: resolve => (require(["@/views/textile_Sales/SOrd"], resolve)),
            },
            {
                path: "SO_Contract",
                name: "Sales_Contract_List",
                component: resolve => (require(["@/views/textile_Sales/SO_Contract"], resolve)),
            },
            {
                path: "SOrdAdd",
                name: "Sales_Order",
                component: resolve => (require(["@/views/textile_Sales/SOrdAdd"], resolve)),
            },
            {
                path: "SO_ContractAdd",
                name: "Sales_Contract",
                component: resolve => (require(["@/views/textile_Sales/SO_ContractAdd"], resolve)),
            }
        ]
    },
    {
        path: "/stealth",
        name: "Stealth",
        component: Layout,
        children: [
            {
                path: "productPrice",
                name: "品种历史价格",
                component: resolve => (require(["@/views/stealth/productPrice"], resolve)),
            },
        ]
    },
    {
        path: "/help",
        name: "Help Manage",
        component: Layout,
        children: [
            {
                path: "issue",
                name: "Issue",
                component: resolve => (require(["@/views/issue/issue"], resolve)),
            },
            {
                path: "issueAdd",
                name: "IssueAdd",
                component: resolve => (require(["@/views/issue/issueAdd"], resolve)),
            },
            {
                path: "issueChat",
                name: "IssueChat",
                component: resolve => (require(["@/views/issue/issueChat"], resolve)),
            },
            {
                path: "issueService",
                name: "IssueService",
                component: resolve => (require(["@/views/issue/issueService"], resolve)),
            }
        ]
    },
]
