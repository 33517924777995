import Layout from '@/page/index/'
import OidcCallback from '@/views/auth/OidcCallback.vue'
import OidcPopupCallback from '@/views/auth/OidcPopupCallback.vue'
import OidcCallbackError from '@/views/auth/OidcCallbackError.vue'

export default [
    {
        path: '/',
        name: '选择公司',
        component: resolve => (require(['@/page/login/company'], resolve)),
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: true
        }
    },
    {
        path: '/company',
        name: '选择公司',
        component: resolve => (require(['@/page/login/company'], resolve)),
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: true
        }
    },
    {
        path: '/logout',
        name: '退出登录',
        component: resolve => (require(['@/page/logout/index'], resolve)),
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: true
        }
    },
    {
        path: '/cn/home',
        name: '首页',
        component: resolve => (require(['@/views/Home/index_cn'], resolve)),
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/en/home',
        name: '首页',
        component: resolve => (require(['@/views/Home/index_en'], resolve)),
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/lock',
        name: '锁屏页',
        component: resolve => (require(['@/page/lock/index'], resolve)),
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/oidc-callback', // Needs to match redirectUri in you oidcSettings
        name: 'oidcCallback',
        component: OidcCallback,
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/oidc-popup-callback', // Needs to match popupRedirectUri in you oidcSettings
        name: 'oidcPopupCallback',
        component: OidcPopupCallback,
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
        name: 'oidcCallbackError',
        component: OidcCallbackError,
        meta: {
            isPublic: true
        }
    },
    {
        path: '/myiframe',
        component: Layout,
        redirect: '/myiframe',
        children: [{
            path: ":routerPath",
            name: 'iframe',
            component: () =>
                import('@/components/iframe/main'),
            props: true
        }]
    },
    {
        path: '/403',
        component: () =>
            import('@/components/error-page/403'),
        name: '403',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    },
    {
        path: '*',
        component: () =>
            import('@/components/error-page/404'),
        name: '404',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    }, {
        path: '/500',
        component: () =>
            import('@/components/error-page/500'),
        name: '500',
        meta: {
            keepAlive: true,
            isTab: false,
            isAuth: false
        }
    }
]
