<template>
  <div class="avue-logo" style="height:72px;">
    <transition name="fade">
      <span v-if="keyCollapse"
            class="avue-logo_subtitle"
            key="0" style="line-height: 60px;">
        {{website.logo}}
      </span>
    </transition>
    <transition-group name="fade">
        <template v-if="!keyCollapse">
            <el-image  
                      :src="logUrl"
                      fit="contain"  key="1"></el-image>
            <!--<span class="avue-logo_title"
    style="font-weight: bold;font-size:20px;line-height:60px;"
    key="1">{{$t(website.indexTitle)}} </span>-->
   
        </template>
    </transition-group>
  </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { CurrpReleaseSystem, CurrpSystemEnvironment } from "@/config/env";
export default {
  name: "logo",
  data () {
      return {
          logUrl: "",

      };
  },
        created() {
            this.logUrl = "../img/logTitle/";
            if (CurrpReleaseSystem == "MES") {
                this.logUrl += "mes_"
            }
            else {
                this.logUrl += "pvh_"
            }
            if (CurrpSystemEnvironment == "dev") {
                this.logUrl += "dev"
            }
            else if (CurrpSystemEnvironment == "test") {
                this.logUrl += "test"
            }
            else if (CurrpSystemEnvironment == "training") {
                this.logUrl += "training"
            }
            else {
                this.logUrl += "product"
            }
            this.logUrl += ".png"
  },
  computed: {
    ...mapGetters(["website", 'keyCollapse'])
  },
  methods: {}
};
</script>

<style lang="scss">
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.avue-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 220px;
  height: 50px;
  line-height: 50px;
  background-color: #20222a;
  font-size: 20px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
  z-index: 1024;
  &_title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
  }
  &_subtitle {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
}
</style>