<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <div class="avue-breadcrumb"
           v-if="showCollapse">
        <i class="iconfont icon-caidanzuo"
           :class="[{ 'icon-caidanyou': isCollapse }]"
           @click="setCollapse" style="font-size:25px !important;line-height:50px;"></i>
      </div>
    </div>
    <h1 class="top-bar__title">
      <!--<div class="top-bar__item top-bar__item--show"
           v-if="showMenu">-->
        <!-- <top-menu></top-menu> -->
      <!--</div>
      <span class="top-bar__item"
            v-if="showSearch">
        <top-search></top-search>
      </span>-->
    </h1>

    <div class="top-bar__right" style="height:25px!important;">

        <el-tooltip effect="dark"
                    :content="$t('Company')"
                    placement="bottom">
            <div class="top-bar__item" style="cursor: pointer;">
                <img style="color: darkslategrey; font-size: 1.125rem !important; width: 1.25rem; text-align: center; vertical-align: middle;"
                     :title="$t('Company')" src="/img/Bnt/company.png" />
            </div>
        </el-tooltip>

        <el-dropdown trigger="click" style="width:auto;padding-right:12px;">
            <span style="padding-top: 4px; font-weight: 400; font-size: 16px; color:dimgrey">
                {{ shortName ? shortName : '' }}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in gridData" :key="item.id" @click.native="changeCompany(item.id)">
                    {{item.name}}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <!--<el-select v-model="companyVal" @change="changeCompany" style="width:300px;margin-right:15px;" size="small">
        <el-option v-for="item in gridData" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
    </el-select>-->
        <el-tooltip effect="dark"
                    :content="$t('UserName')"
                    placement="bottom">
            <div class="top-bar__item" style="cursor: pointer;">
                <img style="color: darkslategrey; font-size: 1.125rem !important; width: 1.25rem; text-align: center; vertical-align: middle;"
                     :title="$t('UserName')" src="/img/Bnt/user.png" />
            </div>
        </el-tooltip>

        <span style="padding-top: 4px; font-weight: 400; font-size: 16px; padding-right:15px;color:dimgrey">
            {{ oidcUser.name }}
        </span>
        <el-tooltip effect="dark"
                    :content="$t('UserName')"
                    placement="bottom">
            <div class="top-bar__item" style="cursor: pointer;">
                <img style="color: darkslategrey; font-size: 1.125rem !important; width: 1.25rem; text-align: center; vertical-align: middle;"
                     :title="$t('Language')" src="/img/Bnt/en.png" />
            </div>
        </el-tooltip>


        <el-dropdown trigger="click" style="width:auto;">
            <span style="padding-top: 4px; font-weight: 400; font-size: 16px; color:dimgrey">
                <!--<img style="width: 25px; height: 23px; text-align: center; vertical-align: middle;"
                     :src="languageVal=='EN'?'/img/lg_EN.png':'/img/lg_CN.png'"
                     alt="" />-->
                {{ languageVal ? languageVal : '' }}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in language" :key="item.value" @click.native="getValue(item)">
                    <!--<div class="icon" style="float: left; margin-left: 10px;">
                        <img style="width: 25px;height: 23px;margin-right: 30px;"
                             :src="item.value=='English'?'/img/lg_EN.png':'/img/lg_CN.png'"
                             alt="" />
                    </div>-->
                    <span style="padding-left: 6px;">{{ item.label }}</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <!--<el-dropdown trigger="click" style="width:auto;">
            <span style="padding-top: 4px; font-weight: 400; font-size: 16px; color:dimgrey">
                <img style="width: 25px; height: 23px; text-align: center; vertical-align: middle;"
                     :src="languageVal=='EN'?'/img/lg_EN.png':'/img/lg_CN.png'"
                     alt="" />
                {{ languageVal ? languageVal : '' }}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in language" :key="item.value" @click.native="getValue(item)">
                    <div class="icon" style="float: left; margin-left: 10px;">
                        <img style="width: 25px;height: 23px;margin-right: 30px;"
                             :src="item.value=='English'?'/img/lg_EN.png':'/img/lg_CN.png'"
                             alt="" />
                    </div>
                    <span style="padding-left: 6px;">{{ item.label }}</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>-->

        <!--<template>
        <el-select v-model="langVal" @change="getValue" style="width:100px;margin-right:15px;" size="small">
            <el-option v-for="item in language" :key="item.value" :label="item.label" :value="item.value">
                <div class="icon" style="float: left; margin-left: 10px;">
                    <img style="width: 25px;height: 23px;margin-right: 30px;"
                         :src="item.value=='English'?'/img/lg_EN.png':'/img/lg_CN.png'"
                         alt="" />
                </div>
                <span style="padding-left: 6px;">{{ item.label }}</span>
            </el-option>
        </el-select>
    </template>-->
        <!--        <el-tooltip v-if="showColor"
                effect="dark"
                content="主题色"
                placement="bottom">
        <div class="top-bar__item">
            <top-color></top-color>
        </div>
    </el-tooltip>-->
        <el-tooltip v-if="showDebug"
                    effect="dark"
                    :content="logsFlag?'没有错误日志':`${logsLen}条错误日志`"
                    placement="bottom">
            <div class="top-bar__item">
                <!-- <top-logs></top-logs> -->

            </div>

        </el-tooltip>
        <!--<el-tooltip v-if="showLock"
                effect="dark"
                content="锁屏"
                placement="bottom">
        <div class="top-bar__item">
            <top-lock></top-lock>
        </div>
    </el-tooltip>-->
        <el-tooltip v-if="showTheme"
                    effect="dark"
                    :content="$t('Characteristic Theme')"
                    placement="bottom">
            <div class="top-bar__item top-bar__item--show">
            </div>
        </el-tooltip>
        <!-- <div class="top-bar__item">
      <top-msg></top-msg>
    </div> -->
        <el-tooltip v-if="showFullScren"
                    effect="dark"
                    :content="isFullScren?$t('Exit Full Screen'):$t('Full Screen')"
                    placement="bottom">
            <div class="top-bar__item" style="cursor: pointer;"
                 v-show="this.$route.name =='首页'|| this.$route.name =='实时看板'|| this.$route.name =='Real time production Kanban' || this.$route.name =='Typesetting Management'||this.$route.name =='Personnel ReplacementS'||this.$route.name =='Equipment Details'||this.$route.name =='Process Modification'||this.$route.name =='Operational Manage'||this.$route.name =='Home Page'||this.$route.name =='PVH Home Page'||this.$route.name =='TracingReport'">
                <i :class="isFullScren?'iconfont icon-tuichuquanping':'iconfont icon-caozuo_quanpingfangda'"
                   @click="handleScreen"></i>
            </div>
        </el-tooltip>
        <el-tooltip effect="dark"
                    :content="$t('Exit System')"
                    placement="bottom">
            <div class="top-bar__item" style="cursor: pointer;">
                <i class="el-icon-switch-button" style="color: darkslategrey; font-size: 1.125rem !important; width: 1.25rem; text-align: center; vertical-align: middle;" @click="toLogout"></i>
            </div>
        </el-tooltip>
        <!--
    <el-tooltip effect="dark"
                :content="$t('User Avatar')"
                placement="bottom">
        <div class="top-bar__item" style="margin-right:0.2rem">
            <avatar :username="oidcUser.name" :size="24"></avatar>
        </div>
    </el-tooltip>-->
        <!--<el-dropdown>
      <span class="el-dropdown-link">
          {{ oidcUser.name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
    <el-dropdown-item>
    <a :href="personalURL" target="_blank">{{$t('personal Information')}}</a>
    </el-dropdown-item>
    <el-dropdown-item>
        <router-link to="/company"> {{ $t('Change Company') }}</router-link>
    </el-dropdown-item>
    <el-dropdown-item>
        <router-link to="/logout"> {{ $t('Exit System') }}</router-link>
    </el-dropdown-item>
    </el-dropdown-menu>
    </el-dropdown>-->

        <top-setting></top-setting>
    </div>
      </div>
    </template>
    <script>
    import {mapGetters, mapActions, mapState} from "vuex";
    import {listenfullscreen} from "@/util/util";
    import topLock from "./top-lock";
    import topMenu from "./top-menu";
    import topSearch from './top-search';
    import topBreadcrumb from "./top-breadcrumb";
    import topColor from "./top-color";
    import topTheme from "./top-theme";
    import topLogs from "./top-logs";
    import topSetting from "./top-setting";
    import topMsg from "./top-msg";
    import { oidcSettings } from "@/config/env";
    import Avatar from 'vue-avatar'
    import Cookies from "js-cookie";

    export default {
      components: {Avatar, topLock, topMenu, topSearch, topBreadcrumb, topColor, topTheme, topLogs, topSetting, topMsg},
      name: "top",
      data() {
          let defVlue = "";
          let FlanguageVal = "";
        if (this.$i18n.locale == "zh") {
            defVlue = "Chinese";
            FlanguageVal = "中文";
        } else {
            defVlue = "English";
            FlanguageVal = "English";
        }
          return {
              languageVal: FlanguageVal,
              companyVal: "",
          gridData: [],
          shortName: '',
          personalURL: "",
          fullscreen: false,
          language: [
            {
              value: 'English',
              label: 'English'
            },
            {
              value: 'Chinese',
              label: '中文'
            }
          ],
          langVal: defVlue,
          confirmLogout: false
        };
      },
      filters: {},
      watch: {
        $route(to, from) {
          console.log(this.$route.name)
        }
      },
      created() {
          this.shortName = localStorage.getItem('shortName');
          this.getCom();

      },
      mounted() {
        this.personalURL = oidcSettings.authority + "/Manage";
        listenfullscreen(this.setScreen);
      },
      computed: {
        ...mapState({
          showDebug: state => state.common.showDebug,
          showColor: state => state.common.showColor,
          showTheme: state => state.common.showTheme,
          showLock: state => state.common.showLock,
          showFullScren: state => state.common.showFullScren,
          showCollapse: state => state.common.showCollapse,
          showSearch: state => state.common.showSearch,
          showMenu: state => state.common.showMenu
        }),
        ...mapGetters([
          "userInfo",
          "isFullScren",
          "tagWel",
          "tagList",
          "isCollapse",
          "tag",
          "logsLen",
          "logsFlag"
        ]),
        ...mapGetters('oidcStore', [
          "oidcUser"
        ])
      },
            methods: {
                toLogout() {
                    this.$router.push({ path: '/logout' })
                },
                companyName() {
                    this.gridData.forEach((f) => {
                        if (f.id == this.companyVal) {
                            console.log('存储缓存')
                            this.shortName = f.shortName
                            localStorage.setItem('shortName', f.name)
                        }
                    })
                },
                changeCompany(e) {
                    //获取菜单
                    this.$store.dispatch('GetMenu', this.companyVal)
                    //删除所有导航标签
                 /*   this.$store.commit('DEL_ALL_TAG');*/
                    this.companyVal = e;
                    this.companyName();
                    //将地址栏项的数据清空掉
                    const grop = "gropId";
                    Cookies.set(grop, this.companyVal);
                    if (this.$route.path != "/tracingSearch/pvhIndex") {
                        if (this.tagList != null && this.tagList != undefined) {
                            for (var j = this.tagList.length - 1; j > -1; j--) {
                                this.tagList.splice(j, 1);
                            }
                        }
                        this.$router.push({ path: this.tagWel.value });
                    }
                    else {
                        this.$router.go(0);
                    }
                 },
        getCom() {
                    let res = this.request.post(
                        this.businessUrl + "corporation/queryCorp"
                    );
                    res.then(v => {
                        v.data.data != null ? (this.gridData = v.data.data) : "";
                       this.companyVal = Cookies.get("gropId");
                    });
                },
        getValue(e) {
                    this.languageVal = e.label;
                    var value = e.value;
          if (value == 'Chinese') {
            localStorage.setItem('locale', 'zh')
            this.$i18n.locale = localStorage.getItem('locale');
          } else if (value == 'English') {
            localStorage.setItem('locale', 'en')
            this.$i18n.locale = localStorage.getItem('locale')
          }
        },
        handleScreen() {
          // fullscreenToggel();
          this.handleFullScreen()
        },
        // 全屏事件
        handleFullScreen() {
          // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
          let element = document.getElementById('home_container');//设置后就是   id==con_lf_top_div 的容器全屏
          if (this.fullscreen) {
            console.log(1)
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
            }
          } else {
            if (element.requestFullscreen) {
              element.requestFullscreen();
            } else if (element.webkitRequestFullScreen) {
              element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
              // IE11
              element.msRequestFullscreen();
            }
          }
          this.fullscreen = false;
        },
        setCollapse() {
          this.$store.commit("SET_COLLAPSE");
        },
        setScreen() {
          let element = document.getElementById('home_container');
          if (!this.isFullScren) {
            if (this.$route.path == "/device/devFile" || this.$route.path == "/listArray/pepoleChange" || this.$route.path == "/wkln/wklnChange" || this.$route.path == "/productionManagement/additionToMachine") {
              element.style.background = "#F2F2F2";
              document.querySelector(".outFormDiv").style.top = "0";
              document.querySelector(".outFormDiv").style.width = "100%";
            }
            if (this.$route.path == "/searchDashboard/visualData") {
              localStorage.setItem('visualDataStatus', true);
              element.style.cssText = "background:url('/img/daBG.png') center 100% no-repeat;";
              document.getElementById("topTit").style.display = "block";
              document.getElementById("topSel").style.display = "none";
              var pub2 = document.querySelectorAll(".public");

              var cha2 = document.querySelectorAll(".charts");
              var bdDiv2 = document.querySelectorAll(".bdDiv");
              for (var i = 0; i < pub2.length; i++) {
                pub2[i].style.cssText = "background:transparent !important;padding-left:0;border:0;";
                pub2[i].children[0].style.cssText = "display:none;";
                pub2[i].children[1].style.cssText = "color:#fff;font-weight:500;width:100%;text-align:center;";
              }
              for (var j = 0; j < cha2.length; j++) {
                cha2[j].style.cssText = "background:transparent !important;";
              }
              for (var k = 0; k < bdDiv2.length; k++) {
                bdDiv2[k].style.cssText = "background:transparent !important;border:1px solid #00bdf2;border-radius:0;color:#fff;";
                var cs2 = bdDiv2[k].className;
                cs2 = cs2.concat(" afDiv");
                bdDiv2[k].className = cs2;
              }
            }
            if (this.$route.path == "/tracingSearch/pvhIndex") {
              element.style.background = "#F2F2F2";
              document.querySelector("#pvhinfo_container").style.cssText = "padding:1.25rem;";
              document.querySelector(".chartsMapDiv").style.height = "35.75rem";
              for (var i = 0; i < document.querySelectorAll(".chartsDiv").length; i++) {
                document.querySelectorAll(".chartsDiv")[i].style.height = "19rem";
              }
              document.querySelector("#mapBig").style.display = "none";
            }
          } else {
            if (this.$route.path == "/device/devFile" || this.$route.path == "/listArray/pepoleChange" || this.$route.path == "/wkln/wklnChange" || this.$route.path == "/productionManagement/additionToMachine") {
              element.style.background = "transparent";
              document.querySelector(".outFormDiv").style.top = "100px";
              document.querySelector(".outFormDiv").style.width = "calc(100% - 13.75rem)";
            }

            if (this.$route.path == "/searchDashboard/visualData") {
              localStorage.removeItem('visualDataStatus')
              element.style.cssText = "background:transparent";
              document.getElementById("topTit").style.display = "none";
              document.getElementById("topSel").style.display = "block";
              var pub3 = document.querySelectorAll(".public");
              var cha3 = document.querySelectorAll(".charts");
              var bdDiv3 = document.querySelectorAll(".bdDiv");
              for (var i = 0; i < pub3.length; i++) {
                pub3[i].style.cssText = "background:#fff !important;padding-left:0.8125rem;border-bottom:1px solid #ebebe;";
                pub3[i].children[0].style.cssText = "display:block;";
                pub3[i].children[1].style.cssText = "color:#000;font-weight:600;width:auto;text-align:left;";
              }
              for (var i = 0; i < cha3.length; i++) {
                cha3[i].style.cssText = "background:#fff !important;";
              }
              for (var i = 0; i < bdDiv3.length; i++) {
                bdDiv3[i].style.cssText = "background:#fff !important;border:0;border-radius:0.625rem;color:#000;";
                var cs3 = bdDiv3[i].className;
                cs3 = cs3.replace(" afDiv", "");
                bdDiv3[i].className = cs3;
              }
              //document.querySelectorAll(".public").style.cssText = "background:#fff !important;"
              //document.querySelectorAll(".charts").style.cssText = "background:#fff !important;"
            }
            if (this.$route.path == "/tracingSearch/pvhIndex") {
              element.style.background = "transparent";
              document.querySelector("#pvhinfo_container").style.cssText = "padding:0 1.25rem 1.25rem;";
              document.querySelector(".chartsMapDiv").style.height = "28.75rem";
              for (var i = 0; i < document.querySelectorAll(".chartsDiv").length; i++) {
                document.querySelectorAll(".chartsDiv")[i].style.height = "15rem";
              }
              document.querySelector("#mapBig").style.display = "inline-block";
            }
          }
          this.$store.commit("SET_FULLSCREN");
        },
        logout() {
          this.confirmLogout = true;
          console.log("logout");
        },
        closeModel() {
          this.confirmLogout = false;
        }
      }
    };
    </script>

    <style lang="scss" scoped>
    .top-bar__right {
      margin-top: -30px;
    }

    ::v-deep .company-name {
      margin-right: 10px;
      font-weight: 300!important;
      font-size: 15px!important;
      font-weight:700;font-size:14px;
      //line-height: 54px!important;
      //display: flex;
      //flex-direction: column;
      //justify-content: center;
    }
    </style>

