var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avue-logo", staticStyle: { height: "72px" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.keyCollapse
          ? _c(
              "span",
              {
                key: "0",
                staticClass: "avue-logo_subtitle",
                staticStyle: { "line-height": "60px" }
              },
              [_vm._v("\n      " + _vm._s(_vm.website.logo) + "\n    ")]
            )
          : _vm._e()
      ]),
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        [
          !_vm.keyCollapse
            ? [
                _c("el-image", {
                  key: "1",
                  attrs: { src: _vm.logUrl, fit: "contain" }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }