import { WebStorageStateStore } from 'oidc-client'

// 配置编译环境和线上环境之间的切换
let baseUrl = '';
let CurrpReleaseSystem = '';
let CurrpSystemEnvironment = '';
let oidcSettings;
let pvhApiBase = '';
let textileBaseUrl = 'http://localhost:5202/';
let warehouseApiBase = 'http://localhost:5202/w/api/';
let datalogApiBase = 'http://localhost:5202/l/api/';
let iconfontVersion = ['567566_qo5lxgtishg', '2863793_ssmq11mk51e'];
let iconfontUrl = `//at.alicdn.com/t/font_$key.css`;

const env = process.env

if (env.NODE_ENV == 'development') {
    // baseUrl = `https://api-test.denseny.com/`;                 // mes-上海测试环境地址
    // baseUrl = `https://api.denseny.com/`;                      //  mes-上海生产环境地址
    baseUrl = `http://192.168.1.91:13001/`;                      //  mes-上海生产环境地址
    // baseUrl = `http://192.168.1.64:12001/`;                    // mes-上海64环境地址
    // baseUrl = ` http://v77jk9.natappfree.cc/`;                 // mes-实现内网穿透
    // baseUrl = `http://192.168.1.12:13001/`;                       // mes-本地开发环境地址
    // baseUrl = `http://192.168.0.140:44302/`;                   // 开发环境地址
    // baseUrl = `http://192.168.1.64:12001/`;                    // 开发环境地址
    pvhApiBase = "https://api-test.denseny.com/tracing/api/";  //pvh-上海测试环境地址
    // pvhApiBase = "https://api.denseny.com/tracing/api/";          //pvh-上海生产环境地址
    // pvhApiBase = "http://192.168.1.64:45302/api/";             //pvh-上海64环境地址
    CurrpReleaseSystem = "MES";
    CurrpSystemEnvironment = "dev";
    oidcSettings =

        {
            // "authority": "https://account-test.spinise.com", //香港-test
            "authority": "https://account-test.denseny.com", //上海-test
            // "authority": "https://account.denseny.com", //上海-生产
            // "authority": "http://192.168.1.64:45310",     //上海-64
            "clientId": "textile_client",
            "redirectUri": "http://localhost:8080/oidc-callback.html",
            "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html",
            "post_logout_redirect_uri": "http://localhost:8080",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }

} else if (env.NODE_ENV == 'hkdev') {
    // baseUrl = `http://192.168.1.64:12001/`; // 开发环境地址
    //baseUrl = `http://192.168.1.64:12001/`; // 开发环境地址
    baseUrl = `https://api-test.spinise.com/`; // 开发环境地址
    //pvhApiBase = `https://localhost:44302/api/`;
    pvhApiBase = `https://api-test.spinise.com/tracing/api/`;

    CurrpReleaseSystem = "PVH";
    CurrpSystemEnvironment = "dev";
    oidcSettings =
        {
            "authority": "https://account-test.spinise.com",
            //"authority": "http://192.168.1.64:45310",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "http://localhost:8080/oidc-callback.html",
            "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html",
            "post_logout_redirect_uri": "http://localhost:8080",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'shdev') {
    baseUrl = `https://api-test.denseny.com/`; // 开发环境地址
    // baseUrl = `http://192.168.2.242:8022/`; // 开发环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "MES";
    CurrpSystemEnvironment = "dev";
    // oidcSettings = { "authority": "http://192.168.1.64:45310", "clientId": "textile_client", "redirectUri": "http://localhost:8080/oidc-callback.html", "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html", "post_logout_redirect_uri": "http://localhost:8080" }
    oidcSettings =
        {
            "authority": "https://account-test.denseny.com",
            "clientId": "textile_client",
            "redirectUri": "http://localhost:8080/oidc-callback.html",
            "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html",
            "post_logout_redirect_uri": "http://localhost:8080",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'pvh_hk_product') {
    baseUrl = `https://api.spinise.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "PVH";
    CurrpSystemEnvironment = "product";
    oidcSettings =
        {
            "authority": "https://account.spinise.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://pvh.spinise.com/oidc-callback.html",
            "popupRedirectUri": "https://pvh.spinise.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://pvh.spinise.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://pvh.spinise.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'pvh_hk_training') {
    baseUrl = `https://api-training.spinise.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "PVH";
    CurrpSystemEnvironment = "training";
    oidcSettings =
        {
            "authority": "https://account-training.spinise.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://pvh-training.spinise.com/oidc-callback.html",
            "popupRedirectUri": "https://pvh-training.spinise.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://pvh-training.spinise.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://pvh-training.spinise.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'pvh_hk_test') {
    baseUrl = `https://api-test.spinise.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "PVH";
    CurrpSystemEnvironment = "test";
    oidcSettings =
        {
            "authority": "https://account-test.spinise.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://pvh-test.spinise.com/oidc-callback.html",
            "popupRedirectUri": "https://pvh-test.spinise.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://pvh-test.spinise.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://pvh-test.spinise.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'mes_sh_product') {
    baseUrl = `https://api.denseny.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "MES";
    CurrpSystemEnvironment = "product";
    oidcSettings =
        {
            "authority": "https://account.denseny.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://mes.denseny.com/oidc-callback.html",
            "popupRedirectUri": "https://mes.denseny.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://mes.denseny.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://mes.denseny.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'mes_sh_training') {
    baseUrl = `https://api-training.denseny.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "MES";
    CurrpSystemEnvironment = "training";
    oidcSettings =
        {
            "authority": "https://account-training.denseny.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://mes-training.denseny.com/oidc-callback.html",
            "popupRedirectUri": "https://mes-training.denseny.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://mes-training.denseny.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://mes-training.denseny.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'mes_sh_test') {
    baseUrl = `https://api-test.denseny.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "MES";
    CurrpSystemEnvironment = "test";
    oidcSettings =
        {
            "authority": "https://account-test.denseny.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://mes-test.denseny.com/oidc-callback.html",
            "popupRedirectUri": "https://mes-test.denseny.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://mes-test.denseny.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://mes-test.denseny.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'pvh_sh_training') {
    baseUrl = `https://api-training.denseny.com/`; //香港环境地址

    pvhApiBase = baseUrl + "tracing/api/";
    CurrpReleaseSystem = "PVH";
    CurrpSystemEnvironment = "training";
    oidcSettings =
        {
            "authority": "https://account-training.denseny.com",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "https://pvh-training.denseny.com/oidc-callback.html",
            "popupRedirectUri": "https://pvh-training.denseny.com/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "https://pvh-training.denseny.com/silent-renew-oidc.html",
            "post_logout_redirect_uri": "https://pvh-training.denseny.com",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
} else if (env.NODE_ENV == 'local') {
    baseUrl = `http://192.168.1.64:12001/`; // 开发环境地址

    pvhApiBase = "http://192.168.1.64:45302/api/";
    CurrpReleaseSystem = "PVH";
    CurrpSystemEnvironment = "dev";
    //oidcSettings = { "authority": "http://192.168.1.64:45310", "clientId": "textile_client", "redirectUri": "http://localhost:8080/oidc-callback.html", "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html", "post_logout_redirect_uri": "http://localhost:8080" }
    oidcSettings =
        {
            "authority": "http://192.168.1.64:45310",
            "monitorSession": false,
            "clientId": "textile_client",
            "redirectUri": "http://192.168.1.64:8088/oidc-callback.html",
            "popupRedirectUri": "http://192.168.1.64:8088/oidc-popup-callback.html",
            "responseType": "code",
            "scope": "openid profile roles textile_client_api offline_access",
            "automaticSilentRenew": true,
            "automaticSilentSignin": false,
            "silentRedirectUri": "http://192.168.1.64:8088/silent-renew-oidc.html",
            "post_logout_redirect_uri": "http://192.168.1.64:8088",
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            stateStore: new WebStorageStateStore({ store: window.sessionStorage })
        }
}


let codeUrl = `${baseUrl}/code`
let businessUrl = baseUrl + `process/`;
let userUrl = baseUrl + `process/`;
let apiBase = businessUrl;


export {
    baseUrl,
    textileBaseUrl,
    warehouseApiBase,
    datalogApiBase,
    iconfontUrl,
    iconfontVersion,
    codeUrl,
    env,
    userUrl,
    businessUrl,
    apiBase,
    pvhApiBase,
    CurrpReleaseSystem,
    CurrpSystemEnvironment,
    oidcSettings
}
