<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { removeGrop } from '@/util/auth'

export default {
  name: 'App',
  beforeCreate: function() {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
    // const plugin = document.createElement("script");
    // 此处判断当前页是否为报表页
      
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCjKLbhThtL7lITJBnrL_ykSFq8091wVWQ&libraries=geometry&language=en"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

    console.log('add google map js.')

  },
  computed: {
    ...mapGetters('oidcStore', [
      'oidcIsAuthenticated'
    ])
  },
  methods: {
    ...mapActions('oidcStore', [
      'authenticateOidcPopup',
      'removeOidcUser',
      'signOutOidc'
    ]),
    userLoaded: function (e) {
      console.log('I am listening to the user loaded event in vuex-oidc', e.detail)
    },
    oidcError: function (e) {
      console.log('I am listening to the oidc oidcError event in vuex-oidc', e.detail);
      removeGrop();
      this.removeOidcUser();
    },
    automaticSilentRenewError: function (e) {
      console.log('I am listening to the automaticSilentRenewError event in vuex-oidc', e.detail);
      removeGrop();
      this.removeOidcUser();
    }
  },
  mounted () {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.addEventListener('vuexoidc:oidcError', this.oidcError)
    window.addEventListener('vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError)
  },
  destroyed () {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.removeEventListener('vuexoidc:oidcError', this.oidcError)
    window.removeEventListener('vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError)
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
    .el-message-box {
        min-width: 26.5rem !important;
        max-height: 60.5rem !important;
        width: auto !important;

        p {
            white-space: nowrap !important;
        }
    }
</style>
