import {getselectData} from '@/api/form'

export default {
    state: {
        formObj: {}
    },
    mutations: {
        formAssignment(state, obj) {
            state.formObj = obj
        }
    },
    actions: {
        getData(commit, params) {
            let data = [];
            let newData = [];
            let labelData = [];
            getselectData({}, params.url).then((response) => {
                /*Object.keys(response.data[0]).forEach((f, index) => {
                    if (f == params.item.vak) {
                        data.push({
                            label: Object.values(response.data[0])[index],
                            value: ''
                        })
                    }
                });*/
                response.data.data.forEach((f, findex) => {
                    Object.keys(f).forEach((z, index) => {
                        if (z == params.item.val) {
                            data.push({
                                label: '',
                                value: Object.values(f)[index],
                                index: findex
                            })
                        }
                    })
                    Object.keys(f).forEach((z, index) => {
                        if (z == params.item.key) {
                            data.push({
                                label: Object.values(f)[index],
                                value: '',
                                index: findex
                            })
                        }
                    })
                });
                data.forEach((f) => {
                    if (f.value) {
                        newData.push(f)
                    }
                    if (f.label) {
                        labelData.push(f)
                    }
                })
                newData.forEach((f) => {
                    f.label = labelData[f.index].label
                })
                params.res(newData)
            })
        }
    },
    modules: {}
}