import {businessUrl, baseUrl, apiBase} from "@/config/env"

const api = {
    SELECTWUCHACHANLIANG: apiBase + 'calculation/errorYield',
    SELECTBYSHEET: apiBase + 'machine/selectBySheet',
    QUERYMACHINE: apiBase + 'machine/queryMachine',
    SELECTBYMACHINEMODELID: apiBase + 'machine/selectByMachineModelId',//编号
    MACHINEMODELSELECTALL: apiBase + 'machineModel/selectAll', // 设备机型
    SELECTWORKSHOPNAME: apiBase + 'department/selectWorkShopName', // 设备机型
    PRODUCTSELECT: apiBase + 'product/select', // 设备品种
    SELECTBATCH: apiBase + 'productBatch/selectBatch', // 设备批次
    SELECTPLANSHEET: apiBase + 'specSheetMachinePlan/selectPlanSheet', // 开台
    SELECTBASESHEET: apiBase + 'specSheetMachineBase/selectBaseSheet', // 开台2
    SPECSHEETMACHINEFACT: apiBase + 'specSheetMachineFact/insert', // 开台2
    QUERYPLANBYMACHINEID: apiBase + 'specSheetMachinePlan/queryPlanByMachineId', // 开台2
    SELECTBYMACHINEID: apiBase + 'fault/selectByMachineId', // 开台2
    CLOSEMACHINE: apiBase + 'specSheetMachineFact/closeMachine', // lj
    SELECTPAGE: apiBase + 'iotColumns/selectPage', // 查询
    UPDATE: apiBase + 'iotColumns/update', // 编辑
    INSERT: apiBase + 'iotColumns/insert', // 新增
    IOTCOLUMNSDELETE: apiBase + 'iotColumns/delete', // 删除
    IOTCOLUMNSAUDIT: apiBase + 'iotColumns/audit', // 审核
    MACHINEMODELSELECTPAGE: apiBase + 'machineModel/selectPage', // 分頁
    QUERYJOBREPLACEDETAILS: apiBase + 'schedule/queryJobReplaceDetails',
    SELECTBYCORPID: apiBase + 'shift/selectByCorpId',
    SELECTGROUPNAME: apiBase + 'department/selectGroupName',
    SELECTALLPOST: apiBase + 'post/selectAllPost',
    SELECTUSERBYDEPT: apiBase + 'corpUsers/selectUserByDept',
    QUERYPRODUCTMACHINEDATA: apiBase + 'productYieldRecord/queryProductMachineData',
    MACHINEMODELINSERT: apiBase + 'machineModel/insert', // 新增
    PROCESSSELECTALL: apiBase + 'process/selectAll', // 查询工序
    MACHINEMODELUPDATE: apiBase + 'machineModel/update', // 编辑设备模型
    MACHINEMODEDELETE: apiBase + 'machineModel/delete', // 编辑设备模型
    IOTCOLUMNSSELECTALL: apiBase + 'iotColumns/selectAll', // 编辑设备模型
    ADDIOTCOLUMNMACHINEMODEL: apiBase + 'machineModel/addIotColumnMachineModel', // 编辑设备模型
    MACHINEMODELSELECTBYMACHINEMODELID: apiBase + 'machineModel/selectByMachineModelId', // 查看物模型关系表
    SELECTDICTBYCATEGORYID: apiBase + 'dict/selectDictByCategoryId', // 查看物模型关系表
    SELECTBYPRIMARYKEY: apiBase + 'machineModel/selectByIdAndCategoryId', // 查看物模型关系表
    MACHINESELECTPAGE: apiBase + 'machine/selectPage', // 查看物模型关系表
    DEPARTMENT: apiBase + 'department/selectPage', // 查看物模型关系表
    //MACHINECOLLECTDATASELECTBYMACHINEID: apiBase + 'machineCollectData/selectByMachineId', // 根据设备id查询最新一条数据
    MACHINECOLLECTDATASELECTBYMACHINEID: apiBase + 'machineSpecParam/selectByMachineId', // 根据设备id查询最新一条数据
    MACHINEENERGYSELECTBYMACHINEID: apiBase + 'machineEnergy/selectByMachineId', // 根据设备id查询能耗参数最新一条数据
    INSERTCUSTOMIZEPLAN: apiBase + 'specSheetMachinePlan/insertCustomizePlan', // 根据设备id查询最新一条数据
    SELECTMACHINEBYGORP: apiBase + "/machine/queryAllMachine",//查询设备明细所有机台
    CORPUSERSINSERT: apiBase + "/corpUsers/insert",//新增员工
    IOTCOLUMNSUPDATESTATUS: apiBase + "/iotColumns/updateStatus",//新增员工
    CALCULATEPRODUCTION: apiBase + "/publicMethods/calculateProduction",//根据米数计算产量
    CALCULATEPRODUCTIONYIELD: apiBase + "publicMethods/productionOverTimeYield",//根据米数计算产量2

    //导入导出
    POSTIMPORTTEMPLATE: apiBase + 'post/importTemplate',
    DEPARTMENTIMPORTTEMPLATE: apiBase + 'department/importTemplate',
    POSTIMPORT: apiBase + 'post/import',
    DEPARTMENTIMPORT: apiBase + 'department/import',
    POSTEXPORT: apiBase + 'post/export',
    //DEPARTMENTEXPORT: apiBase + 'department/exportWorkShop',
    DEPARTMENTEXPORT: apiBase + 'department/export',
    //DEPARTMENTEXPORTGROUP: apiBase + 'department/exportGroup',
    DEPARTMENTEXPORTGROUP: apiBase + 'department/export',
    ENVIRONMENTAREAIMPORTTEMPLATE: apiBase + 'environmentArea/importTemplate',
    ENVIRONMENTAREAIMPORT: apiBase + 'environmentArea/import',
    ENVIRONMENTAEXPORT: apiBase + 'environmentArea/export',
    PROCESSIMPORTTEMPLATE: apiBase + 'process/importTemplate',
    PROCESSIMPORT: apiBase + 'process/import',
    PROCESSEXPORT: apiBase + 'process/export',
    UNITIMPORTTEMPLATE: apiBase + 'unit/importTemplate',
    UNITIIMPORT: apiBase + 'unit/import',
    UNITIEXPORT :apiBase + 'unit/export',
    TUBECOLORIMPORTTEMPLATE :apiBase + 'tubeColor/importTemplate',
    TUBECOLORIMPORT :apiBase + 'tubeColor/import',
    TUBECOLOREXPORT :apiBase + 'tubeColor/export',
    PROCESSENERGYWARNINGIMPORTTEMPLATE :apiBase + 'processEnergyWarning/importTemplate',
    PROCESSENERGYWARNINGIMPORT :apiBase + 'processEnergyWarning/import',
    PROCESSENERGYWARNINGIEXPORT :apiBase + 'processEnergyWarning/export',
    MAINTENANCEORDERIMPORTTEMPLATE :apiBase + 'maintenanceOrder/importTemplate',
    MAINTENANCEORDERIMPORT :apiBase + 'maintenanceOrder/import',
    MAINTENANCEORDEREXPORT :apiBase + 'maintenanceOrder/export',
    FAULTIMPORTTEMPLATE :apiBase + 'fault/importTemplate',
    FAULTIMPORT :apiBase + 'fault/import',
    FAULTEXPORT :apiBase + 'fault/export',
    CORPUSERSIMPORTTEMPLATE :apiBase + 'corpUsers/importTemplate',
    CORPUSERSIMPORT :apiBase + 'corpUsers/import',
    CORPUSERSEXPORT :apiBase + 'corpUsers/export',
    SPECPARAMIMPORTTEMPLATE :apiBase + 'specParam/importTemplate',
    SPECPARAMIMPORT :apiBase + 'specParam/import',
    SPECPARAMEXPORT :apiBase + 'specParam/export',
    DICTIMPORTTEMPLATE :apiBase + 'dict/importTemplate',
    DICTIMPORT :apiBase + 'dict/import',
    DICTEXPORT :apiBase + 'dict/export',
    SPINEMPTYYIELDSELECTALL :apiBase + 'SpinEmptyYield/selectAll',
    SPINEMPTYYIELDSELECTLASTTIME :apiBase + 'SpinEmptyYield/selectLastTime',
    SPINEMPTYYIELDINSERTSPIN :apiBase + 'SpinEmptyYield/insertSpin',
    SPINEMPTYYIELDUPDATE :apiBase + 'SpinEmptyYield/update',
    SPINEMPTYYIELDDELETE :apiBase + 'SpinEmptyYield/delete',
    FAULTSELECTFAULTBYMACHINEID :apiBase + 'fault/selectFaultByMachineId',
    FAULTINSERTFAULT :apiBase + 'fault/insertFault',
    FAULTUPDATE :apiBase + 'fault/update',
    FAULTDELETE :apiBase + 'fault/delete',
    SPINEMPTYYIELDQUERYTOTALEMPTY :apiBase + 'SpinEmptyYield/queryTotalEmpty',
    SPECSHEETMACHINEFACTQUERYFACTYIELD :apiBase + 'specSheetMachineFact/queryFactYield',
    MULTIFUNCTIONALREPORT:apiBase + 'reportCondition/export',
    REPORTCONDITIONEXPORT:apiBase + 'reportCondition/importTemplate',
    FAULTINFORMATIONEXPORT :apiBase + 'faultInformation/export',
    FAULTINFORMATIONEXPORTIMPORT :apiBase + 'faultInformation/import',
    FAULTINFORMATIONEXPORTIMPORTTEMPLATE :apiBase + 'faultInformation/importTemplate',
    MATERIALCOMPARISONEXPORT: apiBase + 'qualityStandard/export',

    // 首页
    MACHINESELECTSTATUS :apiBase + 'machine/selectStatus',
    MACHINEYIELDSELECTYIELDTODAY :apiBase + 'machineYield/selectYieldToday',
    MACHINEDATEYIELDSELECTBYDATE :apiBase + 'machineDateYield/selectByMonth',
    MACHINEDATEYIELDSELECTBYMONTH :apiBase + 'machineDateYield/selectByYear',
    SCHEDULESELECTGROUPNAMEBYTIME :apiBase + 'schedule/selectGroupNameByTime',
    SCHEDULEUSERSELECTNUMBER :apiBase + 'scheduleuser/selectNumber',
    CORPUSERSYIELDSELECTUSERYIELD :apiBase + 'corpUsersYield/selectUserYield',
    DEPARTMENTYIELDSELECTGROUPYIELD :apiBase + 'departmentYield/selectGroupYield',
    SELECTALLBYCORPORATIONID :apiBase + 'machineYield/selectAllByCorporationId',
    SPECSHEETQUERYALLPRODUCT :apiBase + 'specSheet/queryAllProduct',
    SPECSHEETMACHINEFACTQUERYEFFICIENCY :apiBase + 'specSheetMachineFact/queryEfficiency', // 生产效率
    SELECTENERGYCONSUMPTIONTODAY :apiBase + 'hourProductionEnergyConsumption/selectEnergyConsumptionToday', // 当日
    SELECTBYMONTH :apiBase + 'dayProductionEnergyConsumption/selectByMonth', // 当月
    SELECTBYYEAR :apiBase + 'dayProductionEnergyConsumption/selectByYear', // 当年
    SCHEDULEUSEREXPORT :apiBase + 'scheduleuser/export', // 当年


}
export default api
